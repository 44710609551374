import React from 'react'
import WebriQForm from '@webriq/gatsby-webriq-form'

const SendFeedback = () => {
  return (
    <>
      <WebriQForm
        name="Send a message"
        id="message-form-main"
        className="message-form mb-5"
        data-form-id="64c2631d-4329-41dc-9fae-bd2c88faaabb"
        data-thankyou-url="/thank-you"
      >
        <div className="container">
          <div className="row">
            
            <div className="col-lg-12 p-0 mt-4">
              <div className="form-group">
                <textarea
                  // className="form-control"
                  type="text"
                  name="Feedback"
                  id="send_message"
                  rows="4"
                  className="w-100"
                  required
                />
              </div>
              <div className="centered-captcha w-100 mb-4">
                <div className="webriq-recaptcha" />
              </div>
              <div className="form-group">
                <button className="btn-default btn-brown" type="submit">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </WebriQForm>
    </>
  )
}

export default SendFeedback
