import React from 'react'

const FAQs = () => {
  return (
    <div className="content-faq pb-5">
      <div className="pb-5">
        <h2 className="my-4">FAQs</h2>
        <h5 className="mt-5">
          When are group recordings posted and what’s recorded?
        </h5>
        <p>
          After a group session, the recording is processed, edited and
          published over a period of about one week.
        </p>

        <h5 className="mt-5">Who is the program open to?</h5>
        <p>
          The program is designed for accomplished physician leaders who want to
          make a big change in their life. While this is originally designed as
          an invitation-only program -- please reach out to Sandy directly if
          you know of someone who might be interested and we can discuss the
          possibilities.
        </p>

        <h5 className="mt-5">When and how can I cancel?</h5>
        <p>
          Email us anytime and we’ll take care of you
          (support@sandyscottllc.com).
        </p>

        {/* <h5 className="mt-5">When is the retreat in Fort Collins, CO?</h5>
        <p>
          Due to timing and COVID rescheduling concerns, the first annual
          retreat will be held in June 2023, with more details to be announced
          before the end of 2022. If you are no longer a member in June 2023,
          we’ll still honor your admission bonus and look forward to seeing you
          then.
        </p> */}

        <h5 className="mt-5">How do I access the bonuses?</h5>
        <p>
          Email us anytime and we’ll take care of you
          (support@sandyscottllc.com).
        </p>
      </div>
    </div>
  )
}

export default FAQs
