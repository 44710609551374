import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import FAQs from '../components/Faqs'
import SendFeedback from '../components/forms/feedback'
import PasswordProtected from '../components/PasswordProtected'
import { setCookie, getCookie } from '../helpers'

import onboardingGuide from '../images/protected-page/1-onboarding-guide.png'
import programGuide from '../images/protected-page/2-program-guide.png'
import coachingSheet from '../images/protected-page/3-coaching-sheet.png'
import coachingScheduling from '../images/protected-page/4-coaching-scheduling.png'
import toolKit from '../images/protected-page/5-toolkit6.png'
import askingPowerfulQuestions from '../images/protected-page/6-asking-powerful-questions.png'
import lifeAndLeadershipWheel from '../images/protected-page/7-life-and-leadership-wheel.png'
import ladderOfInference from '../images/protected-page/8-ladder-of-inference.png'
import centering from '../images/protected-page/9-centering.png'
import levelsOfListening from '../images/protected-page/10-levels-of-listening.png'
import acknowledgement from '../images/protected-page/11-acknowledgement.png'
import stepFeedback from '../images/protected-page/12-step-feedback.png'
import unihookFromCriticism from '../images/protected-page/13-unihook-from-criticism.png'
import nonVerbalCom from '../images/protected-page/non-verbal-communication.png'
import whatCoachingIs from '../images/protected-page/what-coaching-is.png'
import centering2 from '../images/protected-page/centering.png'
import whatCoachingIsNot from '../images/protected-page/What-coaching-is-NOT.png'
import powerfulQuestions from '../images/7-powerful.png'
import newPhysician from '../images/protected-page/new-physician-way-website-logo.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import iconOverview from '../images/search.png'
import Negotiations from '../images/protected-page/negotiations.png'
import Negotiationspt1 from '../images/negotiations-p1.png'
import Negotiationspt2 from '../images/negotiations-p2.png'
import tiredOfinnteruption from '../images/tiredOfinnteruption.png'

import unHookFromCriticism from '../images/4-steps-to-unhook-from-criticism.png'
import howLeaderInterpretInformation from '../images/how-leaders-interpret-information.png'
import feelAGapInYourLife from '../images/feel-a-gap-in-your-life.png'
import letGoOfSelfReliance from '../images/let-go-of-self-reliance.png'
import confused from '../images/confused-1.jpg'
import missbeing from '../images/miss-being-2.jpg'
import endlessly from '../images/endlessly-3.jpg'
import victim from '../images/victims-4.jpg'
import peak from '../images/peak-5.jpg'

//icons
import PlusIcon from '../images/new-homepage/plus-icon.svg'
import iconPlay from '../images/play.png'
import theNewPhysicianWay from '../images/the-new-physician-way.png'
import innercritic from '../images/Inner-Critic-Advanced.png'
import innermentor from '../images/Inner-Mentor-Advanced.png'
import innerCritic from '../images/Inner-Critic-Founation.png'

// var someElement = document.getElementByClass('test')
// someElement.className += ' newclass'

class ProtectedPages extends React.Component {
  constructor(props) {
    super(props)

    this.toggleCollapsed1 = this.toggleCollapsed1.bind(this)
    this.toggleCollapsed2 = this.toggleCollapsed2.bind(this)
    this.toggleCollapsed3 = this.toggleCollapsed3.bind(this)

    this.state = {
      isCollapsed1: true,
      isCollapsed2: true,
      isCollapsed3: true,
    }
  }
  toggleCollapsed1() {
    this.setState({ ...this.state, isCollapsed1: !this.state.isCollapsed1 })
    console.log(this.state)
  }
  toggleCollapsed2() {
    this.setState({ ...this.state, isCollapsed2: !this.state.isCollapsed2 })
    console.log(this.state)
  }
  toggleCollapsed3() {
    this.setState({ ...this.state, isCollapsed3: !this.state.isCollapsed3 })
    console.log(this.state)
  }

  render() {
    const siteTitle = 'The New Physician Way'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <div className="protected-page-heading text-center text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1
                  className="page-content-heading pt-3"
                  style={{ color: '#765179' }}
                >
                  <img
                    src={theNewPhysicianWay}
                    className="img-fluid"
                    style={{ width: '480px' }}
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="section custom-page reference-materials">
          <div className="container">
            <div className="row mb-5">
              <div className="col text-center">
                <div
                  className="btn hidden visible-xs"
                  onClick={this.toggleCollapsed1}
                >
                  <div className="d-flex">
                    <h2 className="text-center cursorNPW">
                      Reference Materials{' '}
                    </h2>
                  </div>
                  <div className="pl-5 ml-5">
                    <LazyLoadImage
                      alt="Email"
                      className="pt-2 iconhidden"
                      style={{ width: '2.5rem', height: '3.5rem' }}
                      src={PlusIcon}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`collapse dont-collapse-sm ${
                this.state.isCollapsed1 ? 'hide' : ''
              }`}
            >
              <div className="row px-5 py-3">
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="/../../_Onboarding_Guide_The_New_Physician_Way_v1.2.pdf"
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={onboardingGuide}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="/../../_Program_Guide_The_New_Physician_Way_v1.2.pdf"
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={programGuide}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="http://www.sandyscottllc.com/NPWtoolkit.pdf"
                      target="_self"
                    >
                      <img
                        src={toolKit}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://calendly.com/sandyscott/1-1-coaching-npw"
                      target="_self"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={coachingScheduling}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section custom-page incoming-calls">
          <div className="container">
            <div className="row mb-5">
              <div className="col text-center">
                <div
                  class="btn hidden visible-xs"
                  onClick={this.toggleCollapsed2}
                >
                  <div className="d-flex">
                    <h2 className="text-center">Group Calls</h2>
                    <div className="pl-5 ml-5">
                      <LazyLoadImage
                        alt="Email"
                        className="pt-2 iconhidden"
                        style={{ width: '2.5rem', height: '3.5rem' }}
                        src={PlusIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`collapse dont-collapse-sm ${
                this.state.isCollapsed2 ? 'hide' : ''
              }`}
            >
              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>
                    Feel responsible to be the expert and help others by solving
                    their problems?
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 5p MT / 7p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto">
                  <a
                    href="https://www.youtube.com/watch?v=LTqGmKfEkmA"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>
              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>Work with a colleague facing a challenging situation?</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 5p MT / 7p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto">
                  <a
                    href="https://www.youtube.com/watch?v=5-cMmwkIOts"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>What coaching is NOT & the 5 most-common mistakes</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time"></div>
                <div className="col-10 col-lg-2 m-auto">
                  <a
                    href="https://www.youtube.com/watch?v=dWFN8Ddh-v0"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>#1 skill to upgrade your impact on others</b>
                </div>

                <div className="col-10 col-lg-2 m-auto card-time"></div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=yTF8zbrbJXk"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>Hate small talk? Do this instead.</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time"></div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=EPqFcqGxGCs"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>Negotiations & Difficult Conversations #1</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time"></div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=-ntMXr0j-nY"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>
                    Irritated by interruptions? Bored with polite and fake
                    conversations?
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time"></div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=JXQdg8ZFJ8Y"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>Negotiations & Difficult Conversations #2</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time"></div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href=" https://www.youtube.com/watch?v=S0-CPdrooS0https://www.youtube.com/watch?v=S0-CPdrooS0"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              {/* <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto">
                  <b>Spot Coaching (Live Q&A Session)</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-date">
                  June 28th <br /> 12:00 pm (MT)
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
              {/* </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <div title="Add to Calendar" className="addeventatc">
                    Add to Calendar
                    <span className="start">06/28/2022 12:00 PM</span>
                    <span className="timezone">America/Los_Angeles</span>
                    <span className="title">
                      Spot Coaching (Live Q&A Session)
                    </span>
                    <span className="description">
                      <div className="location">
                        <a
                          href="https://us02web.zoom.us/j/4147355437"
                          target="_blank"
                        >
                          https://us02web.zoom.us/j/4147355437
                        </a>
                      </div>
                      <div className="zoom-information">
                        Here is the Zoom call-in information for this call:
                        <br />
                        <ul>
                          <li>
                            From your computer or smartphone:{''}
                            <a
                              href="https://us02web.zoom.us/j/4147355437"
                              target="_blank"
                            >
                              https://us02web.zoom.us/j/4147355437
                            </a>
                          </li>
                          <li>
                            For audio only from your smartphone:
                            +16699009128,,4147355437#
                          </li>
                          <li>
                            For audio only from any phone: +1 669 900 9128 US
                            and enter the Meeting ID: 414 735 5437
                          </li>
                        </ul>
                      </div>
                      <div className="resources">
                        <p>
                          To access your program guide, tool kit and onboarding
                          guide, visit:{' '}
                          <a href="https://sandyscottllc.com/NPW/">
                            https://sandyscottllc.com/NPW/
                          </a>
                        </p>
                        <p>Password: LETSDOTHIS</p>
                      </div>
                    </span>
                  </div>
                </div> */}
              {/* </div> */}

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>Challenge other people's decisions in productive ways</b>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-date">
                  July 7th <br /> 12:00 pm (MT)
                </div> */}
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=UkByqAUc7CM"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-calendar">
                  <div title="Add to Calendar" className="addeventatc">
                    Add to Calendar
                    <span className="start">07/07/2022 12:00 PM</span>
                    <span className="timezone">America/Los_Angeles</span>
                    <span className="title">
                      Challenge other people's decisions in productive ways
                    </span>
                    <span className="description">
                      <div className="location">
                        <a
                          href="https://us02web.zoom.us/j/4147355437"
                          target="_blank"
                        >
                          https://us02web.zoom.us/j/4147355437
                        </a>
                      </div>
                      <div className="zoom-information">
                        Here is the Zoom call-in information for this call:
                        <br />
                        <ul>
                          <li>
                            From your computer or smartphone:{''}
                            <a
                              href="https://us02web.zoom.us/j/4147355437"
                              target="_blank"
                            >
                              https://us02web.zoom.us/j/4147355437
                            </a>
                          </li>
                          <li>
                            For audio only from your smartphone:
                            +16699009128,,4147355437#
                          </li>
                          <li>
                            For audio only from any phone: +1 669 900 9128 US
                            and enter the Meeting ID: 414 735 5437
                          </li>
                        </ul>
                      </div>
                      <div className="resources">
                        <p>
                          To access your program guide, tool kit and onboarding
                          guide, visit:{' '}
                          <a href="https://sandyscottllc.com/NPW/">
                            https://sandyscottllc.com/NPW/
                          </a>
                        </p>
                        <p>Password: LETSDOTHIS</p>
                      </div>
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>Inner Critic (Foundation)</b>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-date">
                  July 12th <br /> 12:00 pm (MT)
                </div> */}
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href=""
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-calendar">
                  <div title="Add to Calendar" className="addeventatc">
                    Add to Calendar
                    <span className="start">07/12/2022 12:00 PM</span>
                    <span className="timezone">America/Los_Angeles</span>
                    <span className="title">
                      Inner Critic (Foundation)(Live Q&A Session)
                    </span>
                    <span className="description">
                      <div className="location">
                        <a
                          href="https://us02web.zoom.us/j/4147355437"
                          target="_blank"
                        >
                          https://us02web.zoom.us/j/4147355437
                        </a>
                      </div>
                      <div className="zoom-information">
                        Here is the Zoom call-in information for this call:
                        <br />
                        <ul>
                          <li>
                            From your computer or smartphone:{''}
                            <a
                              href="https://us02web.zoom.us/j/4147355437"
                              target="_blank"
                            >
                              https://us02web.zoom.us/j/4147355437
                            </a>
                          </li>
                          <li>
                            For audio only from your smartphone:
                            +16699009128,,4147355437#
                          </li>
                          <li>
                            For audio only from any phone: +1 669 900 9128 US
                            and enter the Meeting ID: 414 735 5437
                          </li>
                        </ul>
                      </div>
                      <div className="resources">
                        <p>
                          To access your program guide, tool kit and onboarding
                          guide, visit:{' '}
                          <a href="https://sandyscottllc.com/NPW/">
                            https://sandyscottllc.com/NPW/
                          </a>
                        </p>
                        <p>Password: LETSDOTHIS</p>
                      </div>
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>Inner Critic (Advanced)</b>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-date">
                  July 14th <br /> 02:00 pm (MT)
                </div> */}
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=QBI_wBNH1y4"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-calendar">
                  <div title="Add to Calendar" className="addeventatc">
                    Add to Calendar
                    <span className="start">07/14/2022 02:00 PM</span>
                    <span className="timezone">America/Los_Angeles</span>
                    <span className="title">Inner Critic (Advanced)</span>
                    <span className="description">
                      <div className="location">
                        <a
                          href="https://us02web.zoom.us/j/4147355437"
                          target="_blank"
                        >
                          https://us02web.zoom.us/j/4147355437
                        </a>
                      </div>
                      <div className="zoom-information">
                        Here is the Zoom call-in information for this call:
                        <br />
                        <ul>
                          <li>
                            From your computer or smartphone:{''}
                            <a
                              href="https://us02web.zoom.us/j/4147355437"
                              target="_blank"
                            >
                              https://us02web.zoom.us/j/4147355437
                            </a>
                          </li>
                          <li>
                            For audio only from your smartphone:
                            +16699009128,,4147355437#
                          </li>
                          <li>
                            For audio only from any phone: +1 669 900 9128 US
                            and enter the Meeting ID: 414 735 5437
                          </li>
                        </ul>
                      </div>
                      <div className="resources">
                        <p>
                          To access your program guide, tool kit and onboarding
                          guide, visit:{' '}
                          <a href="https://sandyscottllc.com/NPW/">
                            https://sandyscottllc.com/NPW/
                          </a>
                        </p>
                        <p>Password: LETSDOTHIS</p>
                      </div>
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>Inner Mentor (Advanced)</b>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-date">
                  July 19th <br /> 12:00 pm (MT)
                </div> */}
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=lZVupH6V8qo"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-calendar">
                  <div title="Add to Calendar" className="addeventatc">
                    Add to Calendar
                    <span className="start">07/19/2022 12:00 PM</span>
                    <span className="timezone">America/Los_Angeles</span>
                    <span className="title">Inner Mentor (Advanced)</span>
                    <span className="description">
                      <div className="location">
                        <a
                          href="https://us02web.zoom.us/j/4147355437"
                          target="_blank"
                        >
                          https://us02web.zoom.us/j/4147355437
                        </a>
                      </div>
                      <div className="zoom-information">
                        Here is the Zoom call-in information for this call:
                        <br />
                        <ul>
                          <li>
                            From your computer or smartphone:{''}
                            <a
                              href="https://us02web.zoom.us/j/4147355437"
                              target="_blank"
                            >
                              https://us02web.zoom.us/j/4147355437
                            </a>
                          </li>
                          <li>
                            For audio only from your smartphone:
                            +16699009128,,4147355437#
                          </li>
                          <li>
                            For audio only from any phone: +1 669 900 9128 US
                            and enter the Meeting ID: 414 735 5437
                          </li>
                        </ul>
                      </div>
                      <div className="resources">
                        <p>
                          To access your program guide, tool kit and onboarding
                          guide, visit:{' '}
                          <a href="https://sandyscottllc.com/NPW/">
                            https://sandyscottllc.com/NPW/
                          </a>
                        </p>
                        <p>Password: LETSDOTHIS</p>
                      </div>
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>How to boost morale without raises or other incentives</b>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-date">
                  July 28th <br /> 02:00 pm (MT)
                </div> */}
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=swcRtX09J2E"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
                {/* <div className="col-10 col-lg-2 m-auto card-calendar">
                  <div title="Add to Calendar" className="addeventatc">
                    Add to Calendar
                    <span className="start">07/28/2022 02:00 PM</span>
                    <span className="timezone">America/Los_Angeles</span>
                    <span className="title">
                      How to boost morale without raises or other incentives
                    </span>
                    <span className="description">
                      <div className="location">
                        <a
                          href="https://us02web.zoom.us/j/4147355437"
                          target="_blank"
                        >
                          https://us02web.zoom.us/j/4147355437
                        </a>
                      </div>
                      <div className="zoom-information">
                        Here is the Zoom call-in information for this call:
                        <br />
                        <ul>
                          <li>
                            From your computer or smartphone:{''}
                            <a
                              href="https://us02web.zoom.us/j/4147355437"
                              target="_blank"
                            >
                              https://us02web.zoom.us/j/4147355437
                            </a>
                          </li>
                          <li>
                            For audio only from your smartphone:
                            +16699009128,,4147355437#
                          </li>
                          <li>
                            For audio only from any phone: +1 669 900 9128 US
                            and enter the Meeting ID: 414 735 5437
                          </li>
                        </ul>
                      </div>
                      <div className="resources">
                        <p>
                          To access your program guide, tool kit and onboarding
                          guide, visit:{' '}
                          <a href="https://sandyscottllc.com/NPW/">
                            https://sandyscottllc.com/NPW/
                          </a>
                        </p>
                        <p>Password: LETSDOTHIS</p>
                      </div>
                    </span>
                  </div>
                </div> */}
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>4-steps to make meaning out of criticism</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=dKNOhLb6Sr0"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>
                    How leaders interpret information in more productive ways
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=WLSsmRKb51w"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>
                    Feel a gap in your life? A 10-minute method to assess,
                    prioritize and make a plan
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=DHVNg_JO--8"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>Let go of self-reliance and develop team reliance</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=1dGYnMcae5Q"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>
              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>
                    Confused why a project isn’t moving forward? Get clarity and
                    “unstuck” in 15 min.
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=cOUHAFVd66o"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>

              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>
                    Miss being “in the zone” and want to get back? A model to
                    assess and take action.
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=WSssVIDGYxQ"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>
              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>
                    Are you overcomplicating or endlessly polishing something?
                    Try this instead.
                  </b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=X2G4333rqw0"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>
              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>What to do with the victims & villains in your life</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=JQXJ-BPx9ok"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>
              <div className="row boxes-custom mx-3 py-4">
                <div className="col-10 col-lg-6 m-auto card-description">
                  <b>How to find and experience more Peak Moments this year</b>
                </div>
                <div className="col-10 col-lg-2 m-auto card-time">
                  {/* 1p MT / 3p ET  -*/}
                </div>
                <div className="col-10 col-lg-2 m-auto card-calendar">
                  <a
                    href="https://www.youtube.com/watch?v=VrEBLbCCIfk"
                    target="_blank"
                    className="btn-watch-recording text-center"
                  >
                    Watch the Recording
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section custom-page reference-materials">
          <div className="container">
            <div className="row mb-5">
              <div className="col text-center">
                <div
                  class="btn hidden visible-xs"
                  onClick={this.toggleCollapsed3}
                >
                  <div className="d-flex">
                    <h2 className="text-center">On Demand Resources</h2>
                  </div>
                  <div className="pl-5 ml-5">
                    <LazyLoadImage
                      alt="Email"
                      className="pt-2 iconhidden"
                      style={{ width: '2.5rem', height: '3.5rem' }}
                      src={PlusIcon}
                    />
                  </div>
                </div>
                <p className="text-center subtext-hidden">
                  Updated every 1-2 weeks
                </p>
              </div>
            </div>
            <div
              className={`collapse dont-collapse-sm ${
                this.state.isCollapsed3 ? 'hide' : ''
              }`}
            >
              <div className="row mb-4 pt-3">
                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=cOUHAFVd66o"
                      target="_self"
                    >
                      <img
                        src={confused}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=WSssVIDGYxQ"
                      target="_self"
                    >
                      <img
                        src={missbeing}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=X2G4333rqw0"
                      target="_self"
                    >
                      <img
                        src={endlessly}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=JQXJ-BPx9ok"
                      target="_self"
                    >
                      <img
                        src={victim}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=VrEBLbCCIfk&feature=youtu.be"
                      target="_self"
                    >
                      <img
                        src={peak}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=S0-CPdrooS0"
                      target="_self"
                    >
                      <img
                        src={tiredOfinnteruption}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=5-cMmwkIOts"
                      target="_self"
                    >
                      <img
                        src={newPhysician}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link
                      to="https://www.youtube.com/watch?v=EPqFcqGxGCs&feature=youtu.be"
                      target="_self"
                    >
                      <img
                        src={powerfulQuestions}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="https://www.youtube.com/watch?v=dWFN8Ddh-v0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={whatCoachingIsNot}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/watch?v=cOcgW0vZcYQ&list=PLM9q0rzHYvOY8NnT6by6pkSXvZSWJyCU2&index=3"
                    >
                      <img
                        src={nonVerbalCom}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/watch?v=PNeSRrQlM-E&list=PLM9q0rzHYvOY8NnT6by6pkSXvZSWJyCU2"
                    >
                      <img
                        src={whatCoachingIs}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="https://www.youtube.com/watch?v=yTF8zbrbJXk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={centering2}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="https://www.youtube.com/watch?v=lZVupH6V8qo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={innermentor}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="https://www.youtube.com/watch?v=UkByqAUc7CM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={innerCritic}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="https://www.youtube.com/watch?v=dKNOhLb6Sr0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={unHookFromCriticism}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <a
                      href="https://www.youtube.com/watch?v=WLSsmRKb51w"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={howLeaderInterpretInformation}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </a>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link to="">
                      <img
                        src={askingPowerfulQuestions}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                    <div className="card-description">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="btn-custom"
                            href="https://docs.google.com/document/d/1BC6roXK9vs3WYRwJXLNoZZ3eocEsQlA89OXtX8H3DHA/edit#heading=h.k0t2wayacnp7"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconOverview} width="24px" />
                            Overview (Page 25)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://youtu.be/hoUArvs62RI"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Common mistakes (NEW)
                          </a>
                        </li>
                        <li>
                          <a className="btn-custom" href="">
                            <img src={iconPlay} width="24px" />
                            Tips for facilitation (NEW)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link to="">
                      <img
                        src={ladderOfInference}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                    <div className="card-description">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="btn-custom"
                            href="https://docs.google.com/document/d/1BC6roXK9vs3WYRwJXLNoZZ3eocEsQlA89OXtX8H3DHA/edit#heading=h.pnmz5ae2s82f"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconOverview} width="24px" />
                            Overview (Page 22)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://youtu.be/goK2PlSFw6I"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Overview (NEW)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://youtu.be/h-jGL0Y0h9s"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Stay Low (NEW)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link to="">
                      <img
                        src={levelsOfListening}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                    <div className="card-description">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="btn-custom"
                            href="https://docs.google.com/document/d/1BC6roXK9vs3WYRwJXLNoZZ3eocEsQlA89OXtX8H3DHA/edit#heading=h.sbr6ebj3erxj"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconOverview} width="24px" />
                            Overview (Page 29)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://youtu.be/D09IxZL5_nk"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Biggest mistakes (NEW)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link to="">
                      <img
                        src={acknowledgement}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                    <div className="card-description">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="btn-custom"
                            href="https://docs.google.com/document/d/1BC6roXK9vs3WYRwJXLNoZZ3eocEsQlA89OXtX8H3DHA/edit#heading=h.g92lne5bvkh6"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconOverview} width="24px" />
                            Overview (Page 28)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://www.youtube.com/watch?v=06ihW9gJ4zo&list=PLM9q0rzHYvOY8NnT6by6pkSXvZSWJyCU2&index=6"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Common mistakes (NEW)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="card-shadow">
                    <Link to="">
                      <img
                        src={stepFeedback}
                        className="img-fluid img-file"
                        alt="sandys-program"
                      />
                    </Link>
                    <div className="card-description">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="btn-custom"
                            href="https://docs.google.com/document/d/1BC6roXK9vs3WYRwJXLNoZZ3eocEsQlA89OXtX8H3DHA/edit#heading=h.43b14uf9f0y8"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconOverview} width="24px" />
                            Overview (Page 36)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://www.youtube.com/watch?v=CXba5n8JJkU&list=PLM9q0rzHYvOY8NnT6by6pkSXvZSWJyCU2&index=4"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Overview with Sandy
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://www.youtube.com/watch?v=-bh4uaYq4TI&list=PLM9q0rzHYvOY8NnT6by6pkSXvZSWJyCU2&index=5"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Preparation (NEW)
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn-custom"
                            href="https://www.youtube.com/watch?v=QiSU2Fznnx8&list=PLM9q0rzHYvOY8NnT6by6pkSXvZSWJyCU2&index=3&t=3s"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconPlay} width="24px" />
                            Common Mistakes (NEW)
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row mx-3 py-4">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card-shadow">
                      <a
                        href="https://www.youtube.com/watch?v=DHVNg_JO--8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={feelAGapInYourLife}
                          className="img-fluid img-file"
                          alt="sandys-program"
                        />
                      </a>
                      <div className="card-description">
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="btn-custom"
                              href="https://sandyscottllc.com/Lifewheel.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={iconOverview} width="24px" />
                              Key Concepts
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card-shadow">
                      <a
                        href="https://www.youtube.com/watch?v=1dGYnMcae5Q"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={letGoOfSelfReliance}
                          className="img-fluid img-file"
                          alt="sandys-program"
                        />
                      </a>
                      <div className="card-description">
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="btn-custom"
                              href="https://drive.google.com/file/d/1QW15tiF9S_jNaUY-8tdmcn1l0TVpaIAG/view"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={iconOverview} width="24px" />
                              Key Concepts
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card-shadow">
                      <a
                        href="https://www.youtube.com/watch?v=QBI_wBNH1y4"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={innercritic}
                          className="img-fluid img-file"
                          alt="sandys-program"
                        />
                      </a>
                      <div className="card-description">
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="btn-custom"
                              href="https://sandyscottllc.com/AdvancedIC.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={iconOverview} width="24px" />
                              Key Concepts
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card-shadow">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/watch?v=-ntMXr0j-nY"
                      >
                        <img
                          src={Negotiationspt1}
                          className="img-fluid img-file"
                          alt="sandys-program"
                        />
                      </a>
                      <div className="card-description">
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="btn-custom"
                              href="https://sandyscottllc.com/Negotiations1.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={iconOverview} width="24px" />
                              Key Concepts
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <div className="card-shadow">
                      <Link
                        to="https://www.youtube.com/watch?v=S0-CPdrooS0"
                        target="_self"
                      >
                        <img
                          src={Negotiationspt2}
                          className="img-fluid img-file"
                          alt="sandys-program"
                        />
                      </Link>
                      <div className="card-description">
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="btn-custom"
                              href="https://sandyscottllc.com/Negotiations2.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={iconOverview} width="24px" />
                              Key Concepts
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section custom-page faq">
          <div className="container">
            <div className="row boxes">
              <div className="col-10 m-auto">
                <FAQs />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-10 m-auto mb-5">
                <h5>Send us a note, we’re here to serve</h5>
                <SendFeedback />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default () => {
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    setSuccess(getCookie('pageAuth'))
  }, [])

  const submitHandler = input => {
    if (input === 'LETSDOTHIS') {
      setCookie('pageAuth', true, 30)
      setSuccess(true)
    } else {
      alert('Invalid Password')
    }
  }

  return (
    <>
      {success ? (
        <ProtectedPages />
      ) : (
        <PasswordProtected onSubmit={submitHandler} />
      )}
    </>
  )
}

export const ProtectedQueryPages = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
