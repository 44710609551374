import React, { useState } from 'react'
import newPhysicianLogo from '../images/protected-page/new-physician-way-logo.png'
import SEO from '../components/seo'

const PasswordProtected = ({ onSubmit }) => {
  const [passwordInput, setPasswordInput] = useState('')

  return (
    <>
      <SEO title="The New Physician Way" />
      <div id="passwordAuth">
        <div className="container">
          <div className="text-center">
            <img
              src={newPhysicianLogo}
              className="img-fluid mb-3"
              style={{ width: '10rem' }}
            />
          </div>

          <div className="form-group">
            <label for="exampleInputPassword1">
              <strong>Get access by entering your password.</strong>
            </label>
            <input
              type="password"
              className="form-control mt-1"
              id="exampleInputPassword1"
              placeholder="Password"
              value={passwordInput}
              onChange={e => setPasswordInput(e.target.value)}
            />
          </div>

          <button
            type="submit"
            onClick={() => onSubmit(passwordInput)}
            className="btn text-white login-btn-purple"
          >
            <span className="mx-auto text-center"> Submit</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default PasswordProtected
